'use client';
import {ClickAwayListener} from '@mui/material';
import {FC, ReactElement, useState} from 'react';

import {KPopover, KPopoverProps} from '@/components/alerts/KPopover';
import {KCard, KCardProps} from '@/components/layout/KCard';
import {KMenuItemProps} from './KMenuItem';
import {KMenuList, KMenuListProps} from './KMenuList';

/** menu props */
export type KMenuProps = Partial<KPopoverProps> & {
  /** menu items */
  items: KMenuItemProps[];
  /** optional horizontal menu */
  isRow?: boolean;
  /** optional tabs menu theme */
  isTabs?: boolean;
  /** optional flat with no box shadows */
  isFlat?: boolean;
  /** optional index of active menu item */
  activeIndex?: number;
  /** optional KMenuList MenuListProps */
  menuListProps?: Partial<KMenuListProps>;
  /** optional menu KCardProps */
  menuCardProps?: Partial<KCardProps>;
  /** optional popover element */
  popover?: ReactElement;
  /** optional menu is disabled */
  disabled?: boolean;
  /** optional mouse click to open menu */
  isClickToOpen?: boolean;
};

/**
 * Display menu of KMenuItem items using KMenuList.
 *
 * Optional popover element, wrapped with KCard, using KPopover.
 */
export const KMenu: FC<KMenuProps> = ({
  items,
  popover: button,
  isRow,
  isTabs,
  isFlat,
  activeIndex,
  menuListProps,
  menuCardProps,
  disabled,
  isClickToOpen,
  isOpen: defaultIsOpen,
  children,
  ...popoverProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen ?? false);

  const menu = (
    <>
      {items.length > 0 && (
        <KMenuList
          isRow={isRow}
          isTabs={isTabs}
          isFlat={isFlat}
          activeIndex={activeIndex}
          items={items}
          {...menuListProps}
        />
      )}

      {children}
    </>
  );

  const clickable = isClickToOpen
    ? {
        onClick: () => setIsOpen(!isOpen),
        isOpen,
        disableHoverListener: true,
      }
    : {
        enterNextDelay: 200,
        leaveDelay: 200,
      };

  const popover = (
    <KPopover
      placement={'bottom-start'}
      data-testid={`menu`}
      {...clickable}
      {...popoverProps}
      title={
        <KCard variant={'menu'} data-testid={`popoverCard`} {...menuCardProps}>
          {menu}
        </KCard>
      }
    >
      {button!}
    </KPopover>
  );

  return (
    <>
      {!button && menu}

      {button && disabled && button}

      {button && !disabled && !isClickToOpen && popover}

      {button && !disabled && isClickToOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={'referencable'} data-testid={'kmenu-popup'}>
            {popover}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};
